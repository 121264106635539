//@author: devin

import { Defer } from "./jquery-replacement";

declare global {
    interface Window {
        ProData: any;
    }
}

export class S25Const {
    public static appName: String = "25Live";
    public static defaultTabName: String = "25Live";
    public static tabPrefix: String = "";
    public static searchLengthError: String = "(*) search terms must be at least 2 characters long";

    public static isDevMode: boolean = window.ProData && window.ProData.isDevMode;
    public static instanceId: string = window.ProData && window.ProData.instanceId;
    public static instanceIdWithPrefix: string = window.ProData && window.ProData.instanceIdWithPrefix;
    public static webservicesBaseUrl: string = window.ProData && window.ProData.webservicesBaseUrl;
    public static baseUrl: string = window.ProData && window.ProData.baseUrl;
    public static embedded: Boolean = window.ProData && window.ProData.embedded;
    public static isHostUriSeries25: boolean = window.ProData && window.ProData.isHostUriSeries25;
    public static persistentSessionIdParam: string = window.ProData && window.ProData.persistentSessionIdParam;
    public static allowEmbeddedEventCreation: boolean = window.ProData && window.ProData.allowEmbeddedEventCreation;
    public static embeddedType: string = window.ProData && window.ProData.embeddedType;
    public static embeddedConfig: any;
    public static inApp: boolean = (window.ProData && window.ProData.inApp) || false;
    public static studentHousingEnabled = ["burnside", "diane", "kwhite"].indexOf(S25Const.instanceId) > -1;
    public static crmEnabled = ["burnside", "diane", "kwhite"].indexOf(S25Const.instanceId) > -1;
    public static private = "(Private)";
    public static authDefer: Defer = new Defer();

    //embedded forms that are NOT multi-component ones (for those we just refresh the component, eg calendar/avail)
    public static embeddedFormUsesPostSavePage: Boolean =
        S25Const.embeddedType && ["event-form", "express"].indexOf(S25Const.embeddedType) > -1;

    public static getPersistentSessionIdParam(): Promise<string> {
        return new Promise<string>((resolve) => {
            let ProData = window.ProData;
            ProData.hasPersistentSession = true;
            //ANG-3641 Change from checking for persistentSessionIdParam || embeddedConfigProcessed because session was disappearing now instead check for a session or confirmation no sesion is needed
            if (
                (S25Const.persistentSessionIdParam && S25Const.persistentSessionIdParam.length > 0) ||
                ProData.noPersistentSession
            ) {
                resolve(S25Const.persistentSessionIdParam);
            } else {
                if (ProData && ProData.embedded && ProData.embeddedConfigToken && ProData.httpGet) {
                    ProData.httpGet(
                        ProData.webservicesBaseUrl + "/embedded/config.json?token=" + ProData.embeddedConfigToken,
                        function (resp: any) {
                            try {
                                let json = JSON.parse(resp.responseText);
                                if (json && json.config) {
                                    S25Const.persistentSessionIdParam = json.config.sessionId;
                                    ProData.noPersistentSession = !(
                                        S25Const.persistentSessionIdParam &&
                                        S25Const.persistentSessionIdParam.length > 0
                                    );
                                    S25Const.allowEmbeddedEventCreation =
                                        S25Const.allowEmbeddedEventCreation || json.config.allowEventCreation === "T";
                                    S25Const.embeddedConfig = json.config;
                                    ProData.embeddedConfig = json.config; // Switching to ProData because S25Const can be unreliable in migration
                                    S25Const.embeddedConfig.allowSubscribe =
                                        S25Const.embeddedConfig.allowSubscribe === "T";
                                    if (json.config.css) {
                                        let head = document.getElementsByTagName("head")[0];
                                        let style = document.createElement("style");
                                        style.setAttribute("type", "text/css");
                                        if (style.styleSheet) {
                                            //IE
                                            style.styleSheet.cssText = json.config.css;
                                        } else {
                                            //webkit
                                            style.appendChild(document.createTextNode(json.config.css));
                                        }
                                        head.appendChild(style);
                                    }
                                    resolve("");
                                } else {
                                    resolve("");
                                }
                            } catch (e) {
                                console.error(e);
                                resolve("");
                            }
                        },
                    );
                } else {
                    resolve("");
                }
            }
        });
    }

    public static googleMaps = {
        apiUrl:
            (window.ProData && window.ProData.googleMapsApiUrl) ||
            "https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyBAYvSY7_X41Km2eDsoV4iqu9b4ciXuBE8",
        placeBaseUrl: "https://www.google.com/maps/place",
        placeTypeToken: {
            satellite: "/data=!3m1!1e3",
            terrain: "/data=!3m1!4b1",
        },
    };

    public static eventLocatorRegex: RegExp = /^\d{4}-[A-Za-z]{6}$/i;
    public static partialLocatorRegex: RegExp = /^[Aa][A-Za-z]{5}$/i;

    public static schedulerRole = {
        event: -2,
        location: -1, //yes, the scheduler role id is different between event and location; whoever did that is evil...
    };

    public static requestorRole = {
        event: -1,
    };

    public static instructorRole = {
        event: -3,
    };

    public static billingRole = {
        organization: -1,
    };

    public static itemType: any = {
        0: { type: "plain_text" },
        1: { type: "event" },
        11: { type: "event" }, // starred-event
        2: { type: "organization" },
        3: { type: "contact" },
        4: { type: "space" },
        14: { type: "space" }, // starred-space
        1104: { type: "space" }, // space approval (pending icons); evdetail; links to particular space details, the same as 4-space
        1204: { type: "space" }, // event draft space (pending icons); evdetail; links to space details the same as 1104
        407: { type: "space" }, // space search criteria advanced; evdetail; links to particular space details, the same as 4-space
        6: { type: "resource" },
        10: { type: "task" },
        607: { type: "resource" }, // resource quantity; evdetail; links to particular resource details, the same as 6-resource
        16: { type: "resource" }, // starred-resource
        1106: { type: "resource" }, // resource approval (pending icons); evdetail; links to particular resource details, the same as 6-resource
        1206: { type: "resource" }, // event draft resource (pending icons); evdetail; links to resource details the same as 1106
        10001: { type: "event_requestor" },
        10002: { type: "event_scheduler" },
        10003: { type: "event_instructor" },
        10004: { type: "event_all_other" },
        10051: { type: "draft_scheduler" },
        10052: { type: "draft_requestor" },
        101: { type: "event_searches" }, // pre-defined
        104: { type: "space_searches" }, // pre-defined
        106: { type: "resource_searches" }, // pre-defined
        1000: { type: "starred_reports" }, // Dash Starred reports, links to Reports tab
        1010: { type: "task_search" },
        10101: { type: "task_agenda" },
        10102: { type: "task_active" },
        10103: { type: "task_flagged" },
        10104: { type: "task_byyou" },
        4000: { type: "available_space" },
        4001: { type: "find_location" },
        4002: { type: "find_availability" },
        3001: { type: "event_search" }, // search for events
        3004: { type: "space_search" }, // search for spaces
        3006: { type: "resource_search" }, // search for resources,
        3002: { type: "organization_search" }, // search for organizations
    };

    public static item_type_map: any = {
        space: {
            item2: "&reservation_id=",
        },
        location: {
            item2: "&reservation_id=",
        },
        event: {
            item2: "&reservation_id=",
        },
        resource: {
            item2: "&reservation_id=",
        },
        organization: {
            item2: "&reservation_id=",
        },
        contact: {
            item2: "&reservation_id=",
        },
        task: {
            item2: "&reservation_id=",
        },
    };

    public static itemName2Id: any = {
        event: 1,
        location: 4,
        space: 4,
        room: 4,
        resource: 6,
        organization: 2,
        task: 10,
        contact: 3,
        requirement: 8,
    };

    public static itemId2Name: any = {
        1: "event",
        2: "organization",
        3: "contact",
        4: "location",
        5: "rate_schedule",
        6: "resource",
        7: "requirement",
        9: "report",
        10: "task",
        999: "conflict_block",
    };

    public static evddItemTypeId2ItemTypeId: any = {
        1103: 7,
        1107: 2,
    };

    public static subject2DomainFilter: any = {
        event: { filter: "getEventSearches", placeholder: "Filter by event search..." },
        location: { filter: "getLocationSearches", placeholder: "Filter by space search..." },
        resource: { filter: "getResourceSearches", placeholder: "Filter by resource search..." },
        organization: {
            filter: "getOrganizationTypes",
            prefix: "&type_id=",
            placeholder: "Filter by organization type...",
        },
    };

    public static subject2ItemId: any = {
        event: "event_id",
        location: "space_id",
        resource: "resource_id",
        organization: "acct_id",
        contact: "contact_id",
    };

    public static subject2ItemTypeId: any = {
        event: 1,
        organization: 2,
        contact: 3,
        location: 4,
        resource: 6,
        requirement: 7,
        task: 10,
    };

    public static typeId2ItemId: any = {
        1: "event_id",
        4: "space_id",
        6: "resource_id",
        2: "acct_id",
        3: "contact_id",
    };

    public static typeId2ItemIdB: any = {
        //BB and uniface are inconsistent, we need two of these maps (even in angular codes due to middleware) :(
        1: "event_id",
        4: "space_id",
        6: "resource_id",
        2: "organization_id",
        3: "contact_id",
    };

    public static typeId2Meta: any = {
        1: { itemId: "event_id", mailUrl: "event/mail.json" },
    };

    public static itemId2Display: any = {
        1: { plural: "Events", singular: "Event" },
        3: { plural: "Contacts", singular: "Contact" },
        4: { plural: "Locations", singular: "Location" },
        6: { plural: "Resources", singular: "Resource" },
        2: { plural: "Organizations", singular: "Organization" },
        10: { plural: "Tasks", singular: "Task" },
    };

    public static spaceChangeType2Text: any = {
        1: "Add Feature",
        2: "Remove Feature",
        3: "Update Fill Ratio",
        4: "Update Partition",
        5: "Update Capacity",
    };

    public static custAtrbType2Name: any = {
        "3": "Contact",
        B: "Boolean",
        D: "Date",
        E: "Datetime",
        R: "URL",
        F: "Float",
        I: "Image",
        N: "Integer",
        "4": "Location",
        X: "Long Text",
        "2": "Organization",
        "6": "Resource",
        S: "Text",
        T: "Time",
    };

    public static relationships: any = {
        4: [
            { itemId: 1, itemName: "subdivision of", nodeName: "subdivisionOf" },
            { itemId: 2, itemName: "close to", nodeName: "closeTo" },
            { itemId: 3, itemName: "blocked by", nodeName: "blockedBy" },
            { itemId: 4, itemName: "also assign", nodeName: "alsoAssign" },
            { itemId: 5, itemName: "divides into", nodeName: "dividesInto" },
        ],
        6: [
            { itemId: 8, itemName: "substitute with", nodeName: "substituteWith" },
            { itemId: 9, itemName: "also assign", nodeName: "alsoAssign" },
        ],
    };

    public static publisher_services: String = "https://25livepub.collegenet.com/service/";
    public static publisher_signin: String = "https://25livepub.collegenet.com/t.aspx?z=SignIn";

    public static ms = {
        sec: 1000,
        min: 60 * 1000,
        hour: 60 * 60 * 1000,
        day: 24 * 60 * 60 * 1000,
        month: 30 * 24 * 60 * 60 * 1000,
        year: 365 * 24 * 60 * 60 * 1000,
    };

    public static dows = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
}
